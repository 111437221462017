import React from 'react'

import style from './ErrorMessage.module.css'

export const ErrorMessage = ({
  children,
}: React.PropsWithChildren) => (
  <div className={style['error-message']}>
    {children}
  </div>
)