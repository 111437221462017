// 
// Mostly refactored code from utmLegacy.js

import Cookie from 'js-cookie'

const defaultCookieOptions: Parameters<typeof Cookie.set>[2] = {
  domain: 'livetex.ru',
  path: '/',
  expires: 182,
  secure: false,
}

const marketingParams = [
  'utm_source', 
  'utm_medium', 
  'utm_term', 
  'utm_content', 
  'utm_campaign', 
  'referer', 
  'advcake_trackid', 
  'advcake_url',
];

export const marketingData: Partial<Record<typeof marketingParams[number], string>> = {}

const setReferrer = () => {
  var r = document.referrer || 'direct'
  Cookie.set('lt-marketing-referrer', r, defaultCookieOptions)
};

const updateMarketingParamsFromURLSearchParams = (usp: URLSearchParams) => {
  marketingParams.forEach(p => {
    const value = usp.get(p)
    if (value === null) {
      return
    }
    marketingData[p] = value
  })
}

let isGivenOrWhatever = false
if (navigator.cookieEnabled) {
  const url = new URL(document.location.href)
  const utmSource = url.searchParams.get('utm_source')
  if (
    utmSource
    && /^https:\/\/livetex\.ru/i.test(document.referrer) === false
  ) {
    isGivenOrWhatever = true
    Cookie.set('utm_source', utmSource, defaultCookieOptions)
    Cookie.set('advURL', document.location.href, defaultCookieOptions)
    if (utmSource === 'advcake') {
      Cookie.set('advcake_trackid', '254F4690-C09F-FDBF-576A-C62414955DB7', defaultCookieOptions)
    } else {
      Cookie.set('advcake_trackid', '', {
        ...defaultCookieOptions,
        expires: -1,
      })
    } 
    setReferrer()
  }
  
  updateMarketingParamsFromURLSearchParams(url.searchParams)

  const advURL = Cookie.get('advURL')
  if (advURL) {
    if (isGivenOrWhatever === false) {
      const searchParams = advURL.split('?').pop()
      updateMarketingParamsFromURLSearchParams(new URLSearchParams(searchParams))
    }
    marketingData['advcake_url'] = advURL
  }

  const ltMarketingReferrer = Cookie.get('lt-marketing-referrer')
  if (ltMarketingReferrer) {} else {
    setReferrer()
  }
  marketingData['referer'] = ltMarketingReferrer

  const trackId = Cookie.get('advcake_trackid')
  if (trackId) { 
    marketingData['advcake_trackid'] = trackId
  }

  console.log("marketing data", marketingData)
}