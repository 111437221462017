type Metric = 
  | 'test_free'
  | 'send_free'
  | 'send_registration'
  | 'start_work'
  | 'composite_fill_free'
  | 'composite_send_free'
  | 'composite_send_registration'
  | 'composite_start_work'

interface IYM {
  reachGoal: (metric: Metric) => void
}

export const createYMProvider = () => {
  let name = ''
  const sentMetrics: Metric[] = []

  const setYandexCounterName = (newName: string) => {
    name = newName
  }

  const send = (metric: Metric) => {
    if (name === '') {
      return
    }
    const ym = (window as any)[name] as IYM
    if (ym === undefined) {
      return
    }
    if (sentMetrics.includes(metric)) {
      return
    }
    ym.reachGoal(metric)
    sentMetrics.push(metric)
    console.log(`sent metric ${metric}`)
  }

  return {
    setYandexCounterName,
    send,
  }
}

export const ymProvider = createYMProvider()