import React from 'react'

import { IUserData, Stages } from './types'
import { ymProvider } from './ym'

import { Register } from './views/Register/Register'
import { Confirm } from './views/Confirm/Confirm'
import { Typography } from './components/Typography'
import { FormBlock } from './components/FormBlock/FormBlock'

import styles from './App.module.css'

export const App = () => {
  const [stage, setStage] = React.useState<Stages>(Stages.Registration)
  const [userData, setUserData] = React.useState<IUserData>({
    login: '',
    password: '',
  })

  const handleRegister = (newUserData: IUserData) => {
    setUserData(newUserData)
    setStage(Stages.Confirmation)
  }

  const handlePhoneNumberChange = () => {
    setStage(Stages.Registration)
  }

  const handleConfirm = () => {
    setStage(Stages.Completion)
  }

  return (
    <div className={styles['app']}>
      {stage === Stages.Registration && (
        <Register 
          userData={userData}
          onSubmitted={handleRegister}
        />
      )}
      {stage === Stages.Confirmation && (
        <Confirm 
          userData={userData}
          onSubmitted={handleConfirm}
          onPhoneNumberChange={handlePhoneNumberChange}
        />
      )}
      {stage === Stages.Completion && (
        <>
          <Typography.Text size='large'>
            Вы успешно зарегистрировались!
          </Typography.Text>
          <FormBlock className={styles['gap_size_default']}>
            <button 
              onClick={() => {
                ymProvider.send('start_work')
                ymProvider.send('composite_start_work')
                setTimeout(() => {
                  document.location.href = 'https://my.livetex.ru'
                }, 1000)
              }}
              className={styles['button']}
            >
              Начать работу
            </button>
          </FormBlock>
        </>
      )}
    </div>
  )
}
