import { LoginType } from "./types"

export const isEmailValid = (email: string): boolean => email.includes('@')
export const isPhoneNumberValid = (phoneNumber: string): boolean => /^\d{11,}$/.test(phoneNumber)
export const isPasswordValid = (password: string): boolean => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{10,}$/.test(password)

export const guessLoginType = (login: string): LoginType => {
  switch (true) {
    case isPhoneNumberValid(login):
      return 'phone'
    case isEmailValid(login):
      return 'email'
    default:
      throw new Error('login is invalid')
  }
}