import styles from './Text.module.css'

interface IProps {
  type?: 
    | 'primary'
    | 'secondary'
  size?:
    | 'large'
    | 'default'
  align?: 'center'
}

export const Text = ({
  type = 'primary',
  size = 'default',
  align = 'center',
  children,
}: React.PropsWithChildren<IProps>) => {
  return (
    <p
      className={
        `
        ${styles['text']}
        ${styles[`text_type_${type}`]}
        ${styles[`text_size_${size}`]}
        ${styles[`text_align_${align}`]}
        `
      }
    >
      {children}
    </p>
  )
}