import React from 'react'

import styles from './FormBlock.module.css'

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  label?: string
  extra?: React.ReactNode
}

export const FormBlock = ({
  label,
  extra,
  className = '',
  children,
  ...restOfProps
}: React.PropsWithChildren<IProps>) => {
  return (
    <div {...restOfProps}
      className={
        `
        ${className} 
        ${styles['form-block']}
        `
      }
    >
      <div 
        className={
          `
          ${styles['form-block__item']}
          ${styles['form-block__item_for_label']}
          `
        }
      >
        {label}
      </div>
      <div
        className={
          `
          ${styles['form-block__item']}
          ${styles['form-block__item_for_field']}
          `
        }
      >
        {children}
      </div>
      <div 
        className={
          `
          ${styles['form-block__item']}
          ${styles['form-block__item_for_message']}
          `
        }
      >
        {extra}
      </div>
    </div>
  )
}