import React from 'react'

import { ReactComponent as ImageEye } from './eye.svg'
import { ReactComponent as ImageEyeClosed } from './eye-closed.svg'

import styles from './Input.module.css'

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  hasError?: boolean
}

export const Input = React.forwardRef<HTMLInputElement, IProps>(({
  hasError = false,
  ...props
}, ref) => {
  const [isShown, setIsShown] = React.useState(false)
  const ButtonComponent = isShown ? ImageEyeClosed : ImageEye

  return (
    <div className={styles['input']}>
      <input {...props}
        ref={ref}
        className={
          `
          ${styles['input__control']}
          ${styles[`input__control_type_${props.type}`]}
          ${styles[`input__control_has-error_${hasError ? 'yes' : 'no'}`]}
          `
        }
        type={
          props.type === 'password' && isShown === true 
            ? 'text' 
            : props.type
        }
      />
      {props.type === 'password' && (
        <ButtonComponent 
          className={styles['input__button']}
          onClick={() => setIsShown(!isShown)}
        />
      )}
    </div>
  )
})