import { LoginType } from './types'
import { marketingData } from './utm'

const apiV2BaseURL = (isDebug => {
  if (isDebug) {
    return 'https://apiv2.alpha.dev.livetex.ru/v2'
  }
  return (window as unknown as {
    REGISTER_FORM_SETTINGS: {
      APIV2_URL: string
    }
  })
    .REGISTER_FORM_SETTINGS
    .APIV2_URL + 'v2'
})(process.env.NODE_ENV === 'development')

class ServerError extends Error {
  constructor (code: number) {
    super(`Server returned ${code}`)
    this.name = 'ServerError'
  }
}
class LoginAlreadyExistsError extends Error {
  constructor () {
    super()
    this.name = 'LoginAlreadyExistsError'
  }
}
class IncorrectCodeError extends Error {
  constructor () {
    super()
    this.name = 'IncorrectCodeError'
  }
}

const createAPIProvider = () => {
  interface ISendCodeProps {
    loginType: LoginType
    login: string
    captcha: string
  }
  const sendCode = async ({
    loginType,
    login,
    captcha,
  }: ISendCodeProps): Promise<void> => {
    const urlParams = new URLSearchParams({
      login,
      captcha,
      login_type: loginType,
      method: 'Employees.registerSendCode',
    })
    const url = `${apiV2BaseURL}/?${urlParams.toString()}`
    const r = await fetch(url, {
      method: 'GET',
    })
    if (r.ok === false) {
      throw new ServerError(r.status)
    }
    const json = await r.json()
    if (
      json.results
      && json.results.error
    ) {
      throw new ServerError(r.status)
    }
    return
  }

  interface IRegisterWithCodeProps {
    loginType: LoginType
    login: string
    password: string
    code: string
  }
  const registerWithCode = async ({
    loginType,
    login,
    password,
    code,
  }: IRegisterWithCodeProps): Promise<void> => {
    const marketingDataProps = Object.keys(marketingData).reduce((result, key) => {
      return {
        ...result,
        [`marketing_data[${key}]`]: marketingData[key],
      }
    }, {})
    const commonProps = { 
      ...marketingDataProps,
      login,
      password,
      code,
      login_type: loginType,
    }
    const urlParams = new URLSearchParams({
      ...commonProps,
      method: 'Employees.registerNew',
    })
    const url = `${apiV2BaseURL}/?${urlParams.toString()}`
    const r = await fetch(url, {
      method: 'GET',
    })
    if (r.ok === false) {
      throw new ServerError(r.status)
    }
    const json = await r.json()
    if (
      json.results
      && json.results.error
    ) {
      switch (json.results.error) {
        case 'invalid_code':
          throw new IncorrectCodeError()
        case 'login_exists':
          throw new LoginAlreadyExistsError()
        default:
          throw new ServerError(r.status)
      }
    }
    return
  }

  return {
    sendCode,
    registerWithCode,
    ServerError,
    IncorrectCodeError,
    LoginAlreadyExistsError,
  }
}

export const apiProvider = createAPIProvider()