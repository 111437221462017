import React from 'react'

import { ErrorMap, IUserData } from '../../types'
import { smartCaptchaProvider } from '../../smartCaptcha'
import { apiProvider } from '../../api'
import { ymProvider } from '../../ym'
import { 
  isEmailValid, 
  isPasswordValid, 
  isPhoneNumberValid, 
  guessLoginType, 
} from '../../utils'
import { 
  loginHelpMessage, 
  passwordHelpMessage, 
  somethingWentWrongMessage, 
} from '../../messages'

import { FormBlock } from '../../components/FormBlock/FormBlock'
import { FormMessage } from '../../components/FormMesage/FormMessage'
import { Input } from '../../components/Input/Input'
import { Typography } from '../../components/Typography'
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage'

import styles from '../../App.module.css'

interface IDraft extends IUserData {
  password2: string
}

interface IProps {
  userData: IUserData
  onSubmitted: (data: IUserData) => void
}

export const Register = ({
  userData,
  onSubmitted,
}: IProps) => {
  const [draft, setDraft] = React.useState<IDraft>({
    ...userData,
    password: userData.password,
    password2: userData.password,
    // password: 'asdQWE1231',
    // password2: 'asdQWE1231',
    // login: '79955991101'
  })
  const [errorMap, setErrorMap] = React.useState<ErrorMap<IDraft>>({})
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const loginInputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    loginInputRef.current 
      && loginInputRef.current.focus()
  }, [])

  React.useEffect(() => {
    if (
      draft.login === ''
      || draft.password === ''
      || draft.password2 === ''
    ) {
      return
    }
    ymProvider.send('test_free')
    ymProvider.send('composite_fill_free')
  }, [draft])

  const handleChange = <K extends keyof IDraft>(fieldName: K, value: IDraft[K]) => {
    setDraft(prevDraft => {
      return {
        ...prevDraft,
        [fieldName]: value,
      }
    })
    setErrorMap(prevErrorMap => {
      const {
        [fieldName]: _,
        ...restOfErrorMap
      } = prevErrorMap
      return restOfErrorMap
    })
    setError('')
  }

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(ev.target.name as keyof IDraft, ev.target.value)
  }

  const handleSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault()

    let newErrorMap = validate(draft)
    if (Object.keys(newErrorMap).length > 0) {
      setErrorMap(newErrorMap)
      return
    }

    setIsLoading(true)
    setError('')

    ymProvider.send('send_free')
    ymProvider.send('composite_send_free')

    const {
      password2: _,
      ...userData
    } = draft

    try {
      await apiProvider.sendCode({
        login: userData.login,
        loginType: guessLoginType(userData.login),
        captcha: await smartCaptchaProvider.execute(),
      })
      onSubmitted(userData)
    } catch (e) {
      console.log(e)
      switch (true) {
        case e instanceof smartCaptchaProvider.SmartCaptchaNotLoaded:
        case e instanceof apiProvider.ServerError:
        default:
          // throw e
          setError(somethingWentWrongMessage)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form 
      onSubmit={handleSubmit}
      autoComplete='off'
    >
      <Typography.Text size='large'>
        Получите тестовый доступ за 5 минут!
      </Typography.Text>
      <Typography.Text>
        Оцените все возможности платформы LiveTex <br />
        без ограничений – 14 дней бесплатно! <br /> 
      </Typography.Text>
      <FormBlock
        label="Номер телефона или email"
        extra={
          errorMap.login ? (
            <FormMessage type='error'>
              {errorMap.login}
            </FormMessage>
          ) : (
            <FormMessage type='help-text'>
              {loginHelpMessage}
            </FormMessage>
          )
        }
        className={styles['gap_size_default']}
      >
        <Input 
          ref={loginInputRef}
          value={draft.login}
          onChange={handleInputChange}
          name='login'
          type='text'
          autoComplete='off'
        />
      </FormBlock>
      <FormBlock
        label="Пароль"
        extra={
          errorMap.password ? (
            <FormMessage type='error'>
              {errorMap.password}
            </FormMessage>
          ) : (
            <FormMessage type='help-text'>
              {passwordHelpMessage}
            </FormMessage>
          )
        }
      >
        <Input 
          value={draft.password}
          onChange={handleInputChange}
          name='password'
          type='password'
          placeholder="**********"
          autoComplete="new-password"
        />
      </FormBlock>
      <FormBlock
        label="Подтвердите пароль"
        extra={
          errorMap.password2 && (
            <FormMessage type='error'>  
              {errorMap.password2}
            </FormMessage>
          )
        }
      >
        <Input 
          value={draft.password2}
          onChange={handleInputChange}
          name='password2'
          placeholder="**********"
          type='password'
          autoComplete="new-password"
        />
      </FormBlock>
      <FormBlock
        extra={(
          <FormMessage>
            Отправляя ваши контактные данные вы соглашаетесь с <Typography.Link href='https://livetex.ru/Documents/Politika-obrabotki.pdf'>
              политикой обработки персональных данных
            </Typography.Link>
          </FormMessage>
        )}
      >
        <button 
          disabled={isLoading}
          className={styles['button']}
        >
          {isLoading ? "Загрузка" : "Начать работу"}
        </button>
      </FormBlock>
      {error && (
        <ErrorMessage>
          {error}
        </ErrorMessage>
      )}
    </form>
  )
}

const validate = ({
  login,
  password,
  password2,
}: IDraft): ErrorMap<IDraft> => {
  const errors: ErrorMap<IDraft> = {}

  const normalizedLogin = login.replaceAll(' ', '')
  if (normalizedLogin === '') {
    errors.login = "Введите номер телефона или email адрес."
  } else if (
    !isEmailValid(normalizedLogin)
    && !isPhoneNumberValid(normalizedLogin)
  ) {
    errors.login = "Введите корректный номер телефона (пример: 73213212121) или email адрес (пример: mail@example.com)."
  }
  if (password.length === 0) {
    errors.password = "Введите пароль."
  } else if (!isPasswordValid(password)) {
    errors.password = passwordHelpMessage
  } else {
    if (password !== password2) {
      errors.password2 = "Пароли не совпадают."
    }
  }

  return errors
}