import React from 'react'

export type LoginType = 
  | 'phone' 
  | 'email'

export enum Stages {
  Registration = 1,
  Confirmation,
  Completion,
}

export interface IUserData {
  login: string
  password: string
}

export interface IFieldError<T> {
  fieldName: keyof T
}

export type ErrorMap<T> = Partial<Record<keyof T, React.ReactNode>>