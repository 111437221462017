import React from 'react'

import styles from './FormMessage.module.css'

interface IProps {
  type?: 'error' | 'help-text'
}

export const FormMessage = ({
  type = 'help-text',
  children,
}: React.PropsWithChildren<IProps>) => {
  return (
    <span
      className={
        `
        ${styles['form-message']}
        ${styles[`form-message_type_${type}`]}
        `
      }  
    >
      {children}
    </span>
  )
}