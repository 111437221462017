import React from 'react'

import styles from './Link.module.css'

interface IProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {}

export const Link = ({
  href = '#',
  className = '',
  children,
  ...props
}: IProps) => {
  return (
    <a {...props}
      href={href}
      className={
        `
        ${className}
        ${styles['link']}
        `
      }
    >
      {children}
    </a>
  )
}