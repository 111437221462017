import React from 'react'
import ReactDOM from 'react-dom/client'

import { smartCaptchaProvider } from './smartCaptcha'
import { ymProvider } from './ym'

import { App } from './App'

import './vars.css'
// import './index.css'

(window as any).mountRegisterFormAt = ($el: HTMLElement) => {
  const root = ReactDOM.createRoot($el)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
  return () => root.unmount()
}

// ;(window as any).mountRegisterFormAt(document.getElementById('root'))
;(window as any).handleCaptchaLoaded = smartCaptchaProvider.makeOnLoad('captcha-container')
;(window as any).ymProvider = ymProvider
