import React from 'react'

import { ErrorMap, IUserData } from '../../types'
import { apiProvider } from '../../api'
import { ymProvider } from '../../ym'
import { useTimer } from '../../useTimer'
import { smartCaptchaProvider } from '../../smartCaptcha'
import { guessLoginType } from '../../utils'
import { codeIsIncorrectMessage, loginAlreadyExistsMessage, somethingWentWrongMessage } from '../../messages'

import { FormBlock } from '../../components/FormBlock/FormBlock'
import { FormMessage } from '../../components/FormMesage/FormMessage'
import { Typography } from '../../components/Typography'
import { Input } from '../../components/Input/Input'
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage'

import styles from '../../App.module.css'

const maxNumberOfAttempts = 3
const secondsBeforeResendCode = 90

interface IDraft {
  code: string
}

interface IProps {
  userData: IUserData
  onSubmitted: () => void
  onPhoneNumberChange: () => void
}

export const Confirm = ({
  userData,
  onSubmitted,
  onPhoneNumberChange,
}: IProps) => {
  const [draft, setDraft] = React.useState<IDraft>({
    code: '',
  })
  const [errorMap, setErrorMap] = React.useState<ErrorMap<IDraft>>({})
  const [numberOfAttempts, setNumberOfAttempts] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const codeInputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    codeInputRef.current 
      && codeInputRef.current.focus()
  }, [])

  const {
    seconds,
    start: startTimer,
  } = useTimer()

  const handleCodeSend = async () => {
    setIsLoading(true)
    setError('')

    try {
      await apiProvider.sendCode({
        loginType,
        login: userData.login,
        captcha: await smartCaptchaProvider.execute(),
      })
      startTimer(secondsBeforeResendCode)
      setNumberOfAttempts(0)
    } catch (e) {
      console.log(e)
      switch (true) {
        case e instanceof smartCaptchaProvider.SmartCaptchaNotLoaded:
        case e instanceof apiProvider.ServerError:
        default:
          // throw e
          setError(somethingWentWrongMessage)
      }
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    startTimer(secondsBeforeResendCode)
  }, [startTimer])

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const name = ev.target.name as keyof IDraft
    const value = ev.target.value

    setDraft(prevDraft => {
      return {
        ...prevDraft,
        [name]: value,
      }
    })
    setErrorMap(prevErrorMap => {
      const {
        [name]: _,
        ...restOfErrorMap
      } = prevErrorMap
      return restOfErrorMap
    })
    setError('')
  }

  const handleSendCodeLinkClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    handleCodeSend()
  }

  const handleChangePhoneNumberLinkClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    onPhoneNumberChange()
  }

  const handleSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault()

    let newErrorMap = validate(draft)
    if (Object.keys(newErrorMap).length > 0) {
      setErrorMap(newErrorMap)
      return
    }

    setIsLoading(true)
    setError('')

    ymProvider.send('send_registration')
    ymProvider.send('composite_send_registration')

    try {
      await apiProvider.registerWithCode({
        ...userData,
        ...draft,
        loginType,
      })
      onSubmitted()
    } catch (e) {
      console.log(e)
      switch (true) {
        case e instanceof apiProvider.LoginAlreadyExistsError:
          setError(loginAlreadyExistsMessage)
          break
        case e instanceof apiProvider.IncorrectCodeError:
          setErrorMap(prevErrorMap => ({
            ...prevErrorMap,
            code: codeIsIncorrectMessage,
          }))
          setNumberOfAttempts(prevNumberOfAttempts => {
            return prevNumberOfAttempts + 1
          })
          break
        case e instanceof apiProvider.ServerError:
        default:
          // throw e
          setError(somethingWentWrongMessage)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const loginType = guessLoginType(userData.login)
  const timeLeft = mapSecondsToTimeLeft(seconds)

  return (
    <form onSubmit={handleSubmit}>
      <Typography.Text size='large'>
        Мы выслали код подтверждения на {
          loginType === 'phone' 
            ? "номер" 
            : "адрес"
          }<br />
        {userData.login}
      </Typography.Text>
      <Typography.Text>
        Укажите его в поле ниже.
      </Typography.Text>
      <Typography.Text 
        type='secondary'
      >
        {seconds === 0 ? (
          <Typography.Link onClick={handleSendCodeLinkClick}>
            выслать новый код
          </Typography.Link>
        ) : `выслать повторно через ${timeLeft}`}
      </Typography.Text>
      <Typography.Text 
        type='secondary'
      >
        <Typography.Link onClick={handleChangePhoneNumberLinkClick}>
          указать другой номер/адрес почты
        </Typography.Link>
      </Typography.Text>
      <FormBlock
        extra={
          errorMap.code && (
            <FormMessage type='error'>
              {errorMap.code}
            </FormMessage>
          )
        }
        className={styles['gap_size_default']}
      >
        <Input 
          ref={codeInputRef}
          value={draft.code}
          onChange={handleChange}
          name='code'
          type='text'
          placeholder="Код подтверждения"
          maxLength={6}
          autoComplete="off"
        />
      </FormBlock>
      <FormBlock
        extra={
          numberOfAttempts > maxNumberOfAttempts && (
            <FormMessage type='error'>
              Код подтверждения введен неверно более ${maxNumberOfAttempts} раз. <Typography.Link onClick={handleChangePhoneNumberLinkClick}>
                Измените номер</Typography.Link> или <Typography.Link onClick={handleSendCodeLinkClick}>
                  вышлите новый код {seconds > 0 && `через ${timeLeft}`}
                </Typography.Link>
            </FormMessage>
          )
        }
      >
        <button 
          disabled={isLoading}
          className={styles['button']}
        >
          {isLoading ? "Загрузка" : "Подтвердить"}
        </button>
      </FormBlock>
      {error && (
        <ErrorMessage>
          {error}
          {error === loginAlreadyExistsMessage && (
            <>
              <br />
              <Typography.Link href='https://my.livetex.ru/login/'>
                Войти в Личный Кабинет
              </Typography.Link>
            </>
          )}
        </ErrorMessage>
      )}
    </form>
  )
}

const validate = ({
  code,
}: IDraft): ErrorMap<IDraft> => {
  const errors: ErrorMap<IDraft> = {}

  if (code.trim() === '') {
    errors.code = "Введите код."
  }

  return errors
}

export const mapSecondsToTimeLeft = (seconds: number): string => {
  return `${Math.floor(seconds / 60)}:${('0' + (seconds % 60)).slice(-2)}`
}