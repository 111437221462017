import React from 'react'

export const useTimer = () => {
  const [seconds, setSeconds] = React.useState(0)
  const [isActive, setIsActive] = React.useState(false)

  const timerId = React.useRef<NodeJS.Timeout | null>(null)

  const start = React.useCallback((seconds: number) => {
    setSeconds(seconds)
    setIsActive(true)
  }, [setSeconds])

  React.useEffect(() => {
    if (isActive === false) {
      return
    }

    timerId.current = setTimeout(() => {
      if (seconds < 1) {
        setIsActive(false)
        return
      }
      setSeconds(seconds - 1)
    }, 1000)
    
    return () => {
      if (timerId.current === null) {
        return
      }
      clearTimeout(timerId.current)
    }
  }, [
    isActive,
    seconds,
    setSeconds,
  ])

  return {
    seconds,
    start,
  }
}