const siteKey = '5YXAlakwsCvSArVLOrpIMxbdeh5U86FgPqih6dRG'

export interface IWindowWithSmartCaptcha {
  smartCaptcha?: ISmartCaptcha 
}
type SubscribeEvent =
  | 'challenge-visible'
  | 'challenge-hidden'
  | 'network-error'
  | 'success'
interface ISmartCaptcha {
  render: (selector: string, props: {
    sitekey: string
    invisible: true
  }) => number
  execute: () => void
  reset: () => void
  subscribe: (widgetId: number, event: SubscribeEvent, callback: Function) => (() => void)
}

class SmartCaptchaNotLoaded extends Error {
  constructor () {
    super()
    this.name = "SmartCaptchaNotLoaded"
  }
}

const createSmartCaptchaProvider = () => {
  let smartCaptcha: ISmartCaptcha | undefined
  let widgetId: number

  const makeOnLoad = (selector: string) => () => {
    smartCaptcha = (window as IWindowWithSmartCaptcha).smartCaptcha
    if (smartCaptcha === undefined) {
      throw new SmartCaptchaNotLoaded()
    }

    const $el = document.createElement('div')
    $el.id = selector
    document.body.append($el)

    widgetId = smartCaptcha.render(selector, {
      sitekey: siteKey,
      invisible: true,
    })
  }

  const execute = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (smartCaptcha === undefined) {
        reject(new SmartCaptchaNotLoaded())
        return
      }
      
      smartCaptcha.reset()
      const unsub = smartCaptcha.subscribe(widgetId, 'success', (token: string) => {
        resolve(token)
        unsub()
      })
      smartCaptcha.execute()
    })
  }

  return {
    makeOnLoad,
    execute,
    SmartCaptchaNotLoaded,
  }
}

export const smartCaptchaProvider = createSmartCaptchaProvider()

